// Dependency
import React from "react"
import {Link} from "gatsby";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay} from "swiper";
import Fade from 'react-reveal/Fade';
import Img from "gatsby-image";

// Styles
import * as teamGridStyles from './team-grid.module.scss'

// Assets
import quoteIcon from './../quote/assets/quoteIcon.png';

// Internationalization
import { useTranslation } from 'react-i18next';


// install Swiper modules
SwiperCore.use([ Autoplay]);

// Template
const TeamGrid = (props) => {

    const { t } = useTranslation();

    return (
        <section className={teamGridStyles.cdTeamGridSection}>
            <div className="cd-max-width">
                <div className={teamGridStyles.cdTeamHeading}>
                    <Fade bottom cascade>
                        <img src={quoteIcon} alt="Quote Icon" />
                        <h3>{t('team.headline')}</h3>
                        <Link className="cd-button cd-button-outline cd-button-outline-white" to={`/${props.locale}/coworkers`}>{t('team.button')}</Link>
                    </Fade>
                </div>
                <Swiper className={`${teamGridStyles.cdTeamSwiper} cd-swiper`}
                        spaceBetween={40}
                        slidesPerView={4}
                        slidesPerGroup={1}
                        autoplay={{
                            delay: 2200
                        }}
                        watchSlidesVisibility={true}
                        breakpoints={{
                            1200: {
                                spaceBetween: 40,
                                slidesPerView: 4,
                                slidesPerGroup: 1
                            },
                            1000: {
                                spaceBetween: 20,
                                slidesPerView: 3,
                                slidesPerGroup: 1
                            },
                            700: {
                                spaceBetween: 14,
                                slidesPerView: 2,
                                slidesPerGroup: 1
                            },
                            0:{
                                spaceBetween: 14,
                                slidesPerView: 1,
                                slidesPerGroup: 1
                            }
                        }}
                >
                    {props.data.map( (teamCard, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <Link to={`/${props.locale}/coworker/${teamCard.slug}`} className={teamGridStyles.cdTeamCard}>
                                    <div className={`${teamGridStyles.cdTeamCardImg} cd-background-img`}>
                                        <Img fluid={teamCard.image.fluid} alt="" />
                                    </div>
                                    <div>
                                        <h4>{teamCard.name}</h4>
                                        <p>{teamCard.title}</p>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        )
                    } )}
                </Swiper>
            </div>
        </section>
    )
}

export default TeamGrid
