// Dependency
import React, { Component } from "react";
import { ParallaxBanner } from 'react-scroll-parallax';
import Fade from 'react-reveal/Fade';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import {graphql} from "gatsby";


// Components
import Layout from "../../components/layouts";
import BoxGrid from "../../components/box-grid/box-grid";
import TeamGrid from "../../components/team-grid/team-grid";
import FooterText from "../../components/footer-text/footer-text";

// Styles
import * as contactStyles from './contact.module.scss';

// Assets
import phone from "../../images/contact/phone.png";
import email from "../../images/contact/email.png";
import hero from "../../images/hero/contact.jpeg";

// GraphQL to get Dato data
export const query = graphql`
query ( $locale: String! = "en"){
  datoCmsContactPage (locale: {eq: $locale}){
    title
    heroContent
    desktopHero{
      url
    }
    mobileHero{
      url
    }
    address
    phone
    email
    workAreas{
      title
      slug
      description
    }
    team{
      name
      image{
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      slug
      title
    }
  }
}
`

// Template
export class ContactPage extends Component {

    render() {

        const metaData = {
            name: 'Kontakt',
            image:{
                src: hero,
            }
        }
        const pageData = this.props.data.datoCmsContactPage;

        return (
            <Layout data={metaData} locale={this.props.pageContext.locale} path={this.props.location}>
                <section className={contactStyles.cdContactHero}>
                    <div className="cd-max-width">
                        <Fade>
                            <div dangerouslySetInnerHTML={{__html: pageData.heroContent}}></div>
                        </Fade>
                    </div>
                </section>

                <div className="cd-max-width">
                    <div className={contactStyles.cdContactHeroWrapper}>
                        <ParallaxBanner
                            className={contactStyles.cdContactHeroImg}
                            layers={[
                                {
                                    image: pageData.desktopHero.url,
                                    amount: 0.2,
                                }
                            ]}
                        >
                        </ParallaxBanner>
                    </div>

                    <div className={contactStyles.cdContactSticky}>
                        <div className={contactStyles.cdContactStickyLeft}>
                            <div className={contactStyles.cdContactStickyLeftContent}>
                                <Map
                                    google={this.props.google}
                                    zoom={15}
                                    style={this.props.mapStyle}
                                    initialCenter={{lat: 59.3366037070868, lng: 18.066573462885607}}
                                >
                                    <Marker position={{lat: 59.3366037070868, lng: 18.066573462885607}}/>
                                </Map>
                            </div>
                        </div>
                        <div className={contactStyles.cdContactStickyRight}>
                            <div className={contactStyles.cdContactStickyRightContent}>
                                <h6>Kontakta Oss</h6>
                                <h2>GRO Advokatbyrå AB</h2>
                                <div className={contactStyles.cdContactStickyText}>
                                    <div dangerouslySetInnerHTML={{__html: pageData.address}}></div>
                                </div>
                                <div className={contactStyles.cdContactStickyContact}>
                                    <div>
                                        <img src={phone} alt="Phone Icon"/>
                                        <p>{pageData.phone}</p>
                                    </div>
                                    <div>
                                        <img src={email} alt="Email Icon"/>
                                        <a href={`mailto:${pageData.email}`}>{pageData.email}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    pageData && pageData.workAreas && <BoxGrid locale={this.props.pageContext.locale} data={pageData.workAreas} />
                }

                {
                    pageData && pageData.team && <TeamGrid locale={this.props.pageContext.locale} data={pageData.team} />
                }

                <FooterText />

            </Layout>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBFxIFBDSvsRkSD7FpcaYGBN5U6Oeqo6OI'
})(ContactPage);
