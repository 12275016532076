// Dependency
import React from "react"
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "gatsby";

// Styles
import 'swiper/swiper.scss';
import * as boxGridStyles from './box-grid.module.scss'

// Assets

// Template
const BoxGrid = (props) => {

    return (
        <section className={boxGridStyles.cdBoxGridSection} id="what-we-do">
            <div className="cd-max-width">
            {/*    <Reveal effect="typewriter">
                    <div className="">
                        <h6>What we do</h6>
                    </div>
                </Reveal>*/}
                <Swiper className={`${boxGridStyles.cdBoxGridSwiper} cd-swiper`}
                        spaceBetween={14}
                        slidesPerView={4}
                        slidesPerGroup={1}
                        watchSlidesVisibility={true}
                        breakpoints={{
                            1200: {
                                spaceBetween: 14,
                                slidesPerView: 4,
                                slidesPerGroup: 1
                            },
                            900: {
                                spaceBetween: 14,
                                slidesPerView: 3,
                                slidesPerGroup: 1
                            },
                            600: {
                                spaceBetween: 14,
                                slidesPerView: 2,
                                slidesPerGroup: 1
                            },
                            0:{
                                spaceBetween: 14,
                                slidesPerView: 1,
                                slidesPerGroup: 1
                            }
                        }}
                >
                    {props.data.map( (box, i) => {
                        return (
                            <SwiperSlide key={i} className={boxGridStyles.swiperSlide}>
                                <Link to={`/${props.locale}/${box.slug}`} className={boxGridStyles.cdBoxItem}>
                                    <div className={boxGridStyles.cdBoxItemContent}>
                                        <h3>{box.title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: box.description}}></div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        )
                    } )}
                </Swiper>
            </div>
        </section>
    )
}

export default BoxGrid
