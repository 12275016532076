// extracted by mini-css-extract-plugin
export var cdContactHero = "contact-module--cd-contact-hero--V0Blx";
export var cdContactHeroImg = "contact-module--cd-contact-hero-img--DuNy6";
export var cdContactHeroWrapper = "contact-module--cd-contact-hero-wrapper--+ZAcM";
export var cdContactSticky = "contact-module--cd-contact-sticky--YttaJ";
export var cdContactStickyContact = "contact-module--cd-contact-sticky-contact--uWvWN";
export var cdContactStickyLeft = "contact-module--cd-contact-sticky-left--df888";
export var cdContactStickyLeftContent = "contact-module--cd-contact-sticky-left-content--2SmZ4";
export var cdContactStickyRight = "contact-module--cd-contact-sticky-right--mr36j";
export var cdContactStickyRightContent = "contact-module--cd-contact-sticky-right-content--zbzfF";
export var cdContactStickyText = "contact-module--cd-contact-sticky-text--957K-";